html {
  overflow-y: auto;
  scrollbar-gutter: stable;
}

body {
  background-color: #f6f9fd;
  margin: 0;
  padding-right: 0 !important;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0px 2px rgb(0 0 0 / 30%);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 30%);
  border-radius: 12px;
  opacity: 0.3;
}
